<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'area-clienti-dashboard' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna alla Dashboard
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
        <h1>Accettazione Contratto n. {{ this.dati.licenseData.ref_code }}</h1>
        <p>Tipo licenza: <strong>{{ this.dati.licenseData.type }}</strong><br />
          id licenza passata: <strong>{{ this.id_licenza }}</strong></p>

        <p>licenza già accettata => visualizzo il pulsante "paga su stripe" (riga 124)<br /><br />
        /*
        ma qui dovrei sapere dati dati dell'api se è già stato creata la subscription su Stripe per questa licenza
        così da visualizzare sono in caso negativo il pulsante per pagare !!!!!
        */</p>
        

        <hr />
        <div v-if="this.stato_licenza == 'da_accettare'">
          <b-button variant="outline-primary" @click="AccettaContratto(dati.licenseData.contract.id, dati.licenseData.price, '93.146.114.212')">Accetta Contratto</b-button>
        </div>
        <div v-else-if="this.stato_licenza == 'accettato'">
          <b-button variant="primary" @click="PagaContratto()">Paga su Stripe</b-button>
        </div>
        <div v-else-if="this.stato_licenza == 'omaggio_auto_attivato'">
          <p>Questa licenza essendo gratuita -> non si passa da Stripe ma si dovrà attivare la licenza automaticamente ...</p>

        </div>
        <div v-else>
          <h3>licenza accetatta e pagata</h3>
          da vidualizzare il riepilogo dei dati della licenza ...
        </div>
    </div>

      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, VBToggle, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,

    showdata,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      Caricato: false,
      errore_caricamento: false,
      id_licenza: router.currentRoute.params.id_riga,
      dati: [],
      stato_licenza: 'da_accettare',
      IP_cliente: '',
    }
  },
  created() {
    //rileva IP connessione utente
    this.IP_cliente = this.rilevaIP();
        
    //console.log(navigator.userAgent)

    this.$http.get('api/customer/license/card/'+this.id_licenza).then(response => { 
        /*  
        console.log(response)
        console.log("stato esterno -> "+response.status)
        console.log("stato interno -> "+response.data.statusCode)
        */

        if(response.status===200){
          //ok dati ricevuti
          // check autorizzazione ad accedere ai dati oppure no
          if(response.data.statusCode===200){
            //ok autorizzato ! => proseguo
            this.dati = response.data.reply

            console.log("FINE --------------------")
            console.log(this.dati)

            if(this.dati.licenseData.contract.accepted_at === null){
              //licenza da accettare da parte del cliente
              this.stato_licenza = 'da_accettare'
            } else {
              if(this.dati.licenseData.status.id == '20'){
                //licenza già accettata => visualizzo il pulsante "paga su stripe"
                /*
                ma qui dovrei sapere dati dati dell'api se è già stato creata la subscription su Stripe per questa licenza
                così da visualizzare sono in caso negativo il pulsante per pagare !!!!!
                */
                if(this.dati.licenseData.price === 0){
                  //licenza omaggio
                  this.stato_licenza = 'omaggio_auto_attivato'
                } else {
                  this.stato_licenza = 'accettato'
                }
              } else {
                //già pagata -> stati succesivi
                this.stato_licenza = 'gia_pagato'

              }
            }
            this.Caricato = true;
          } else {
            //accesso a contratto che non è il tuo! => accesso "Unauthenticated" 
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          if(response.status===401){
            //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
            console.error("Errore api -> "+response.data.reply['message'])

            if(logoutGeneral() === true){
              // Redirect to login page
              //this.$router.push({ name: 'auth-login' })
              this.$router.replace('/login?status=again')
            }
          }

          this.Caricato = false;
          this.errore_caricamento = true;
        }
      
      }).catch(e => {
        /*
        this.Caricato = false;
        this.errore_caricamento = true;
        */

        console.log("Errore api ......")
        console.log(e)

        this.Caricato = false;
        this.errore_caricamento = true;

      });

  },
  methods: {
    rilevaIP(){
      fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        //console.log("Ip rilevato -> "+ip);
        return ip;
      });
    },
    AccettaContratto(id_contratto, prezzo, ip_connessione_cliente){
      this.Caricato = false;

      console.log("id_contratto -> "+id_contratto+"\nip -> "+ip_connessione_cliente)

      this.$http.post('/api/customer/contract/accept/'+id_contratto, {
        user_agent: navigator.userAgent,
        ip_address: ip_connessione_cliente
      }).then(response => { 
        console.log(response)

        if(prezzo === 0){
          //licenza gratuita
          this.stato_licenza = 'omaggio_auto_attivato',
          this.Caricato = true;
        } else {
          this.stato_licenza = 'accettato',
          this.Caricato = true;
        }
      
      }).catch(e => {
        if(prezzo === 0){

        } else {
          //così rimane il pulsante di "accettazione"
          this.stato_licenza = 'da_accettare',

          this.Caricato = true;
          console.log(e);
        }
      });      
    },
    PagaContratto(){
      this.Caricato = false;

      this.$http.post('/api/customer/stripe/checkout/'+this.id_licenza).then(response => { 
        console.log(response)
        this.Caricato = true;

        console.log("redirect ->\n\n"+response.data.reply.checkout_url)

        //redirect verso stripe
        window.location.href = response.data.reply.checkout_url;

        
      
      }).catch(e => {
        this.Caricato = true;
        console.log(e);
      }); 

    },
    
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
